.unsubscribe {
  color: white;
  padding-top: 20px;
}

body {
  text-decoration: none;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Calibri, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

#logo-text {
  padding: 5px 10px;
  display: inline-block;
  color: white;
  font-size: 14px;
  border-radius: 20px;
  margin: 0px 5px;
}

.colored-background {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

#powered-by-container {
  margin-top: 10px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

#answer-options {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 10px;
}

#rich-container {
  margin-top: 60px;
  max-width: 500px;
  float: left;
  border: 1px solid #AAAAAA;
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

a {
  color: black;
  font-size: 14px;
  text-decoration: none;
}

.link-container {
  flex-direction: row;
  display: flex;
  justify-content: start;
  margin: 10px;
}

.text-container {
  padding-left: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.title {
  font-size: 16px;
}

.subtitle {
  font-size: 12px;
}

.hr {
  width: 100%;
}

.response_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #4080E3;
}
