#searchText:focus, #searchText:focus, #searchText:focus {
  outline: none !important;
}

.result {
  transition: all 0.5s ease;
}

.grow {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  transition: all 200ms;
}

.grow:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.pill-link {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 30px 4px 0px;
  cursor: pointer;
  border-radius: 16px;
}

.pill-link:hover {
  background-color: #3198D8;
  color: white;
}

.pill-link.selected {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  background-color: #3198D8;
  color: white;
}

// FOR SIDE SCROLLING VIDEOS
.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  /*border: 1px blue solid;*/
}
.menu-item.active {
  /*border: 1px green solid;*/
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

