/*********************************************
 * CONTROLS
 *********************************************/

@keyframes bounce-right {
  0%, 10%, 25%, 40%, 50% {transform: translateX(0);}
  20% {transform: translateX(10px);}
  30% {transform: translateX(-5px);}
}

@keyframes bounce-left {
  0%, 10%, 25%, 40%, 50% {transform: translateX(0);}
  20% {transform: translateX(-10px);}
  30% {transform: translateX(5px);}
}

@keyframes bounce-down {
  0%, 10%, 25%, 40%, 50% {transform: translateY(0);}
  20% {transform: translateY(10px);}
  30% {transform: translateY(-5px);}
}

$controlArrowSize: 3.6em;
$controlArrowSpacing: 1.4em;
$controlArrowLength: 2.6em;
$controlArrowThickness: 0.5em;
$controlsArrowAngle: 45deg;
$controlsArrowAngleHover: 40deg;
$controlsArrowAngleActive: 36deg;

@mixin controlsArrowTransform( $angle ) {
  &:before {
    transform: translateX(($controlArrowSize - $controlArrowLength)/2) translateY(($controlArrowSize - $controlArrowThickness)/2) rotate( $angle );
  }

  &:after {
    transform: translateX(($controlArrowSize - $controlArrowLength)/2) translateY(($controlArrowSize - $controlArrowThickness)/2) rotate( -$angle );
  }
}

.reveal .controls {
  $spacing: 12px;

  display: none;
  position: absolute;
  top: auto;
  bottom: $spacing;
  right: $spacing;
  left: auto;
  z-index: 11;
  color: #000;
  pointer-events: none;
  font-size: 10px;

  button {
    position: absolute;
    padding: 0;
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: currentColor;
    transform: scale(.9999);
    transition: color 0.2s ease,
    opacity 0.2s ease,
    transform 0.2s ease;
    z-index: 2; // above slides
    pointer-events: auto;
    font-size: inherit;

    visibility: hidden;
    opacity: 0;

    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );
  }

  .controls-arrow:before,
  .controls-arrow:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: $controlArrowLength;
    height: $controlArrowThickness;
    border-radius: $controlArrowThickness/2;
    background-color: currentColor;

    transition: all 0.15s ease, background-color 0.8s ease;
    transform-origin: floor(($controlArrowThickness/2)*10)/10 50%;
    will-change: transform;
  }

  .controls-arrow {
    position: relative;
    width: $controlArrowSize;
    height: $controlArrowSize;

    @include controlsArrowTransform( $controlsArrowAngle );

    &:hover {
      @include controlsArrowTransform( $controlsArrowAngleHover );
    }

    &:active {
      @include controlsArrowTransform( $controlsArrowAngleActive );
    }
  }

  .navigate-left {
    // EDIT: MOVE LEFT TO LEFT SIDE -- right: $controlArrowSize + $controlArrowSpacing*2;
    left: $controlArrowSpacing;

    bottom: $controlArrowSpacing + $controlArrowSize/2;
    transform: translateX( -10px );

    &.highlight {
      animation: bounce-left 2s 50 both ease-out;
    }
  }

  .navigate-right {
    right: 0;
    bottom: $controlArrowSpacing + $controlArrowSize/2;
    transform: translateX( 10px );

    .controls-arrow {
      transform: rotate( 180deg );
    }

    &.highlight {
      animation: bounce-right 2s 50 both ease-out;
    }
  }

  .navigate-up {
    right: $controlArrowSpacing + $controlArrowSize/2;
    bottom: $controlArrowSpacing*2 + $controlArrowSize;
    transform: translateY( -10px );

    .controls-arrow {
      transform: rotate( 90deg );
    }
  }

  .navigate-down {
    right: $controlArrowSpacing + $controlArrowSize/2;
    bottom: -$controlArrowSpacing;
    padding-bottom: $controlArrowSpacing;
    transform: translateY( 10px );

    .controls-arrow {
      transform: rotate( -90deg );
    }

    &.highlight {
      animation: bounce-down 2s 50 both ease-out;
    }
  }

  // Back arrow style: "faded":
  // Deemphasize backwards navigation arrows in favor of drawing
  // attention to forwards navigation
  &[data-controls-back-arrows="faded"] .navigate-up.enabled {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  // Back arrow style: "hidden":
  // Never show arrows for backwards navigation
  &[data-controls-back-arrows="hidden"] .navigate-up.enabled {
    opacity: 0;
    visibility: hidden;
  }

  // Any control button that can be clicked is "enabled"
  .enabled {
    visibility: visible;
    opacity: 0.9;
    cursor: pointer;
    transform: none;
  }

  // Any control button that leads to showing or hiding
  // a fragment
  .enabled.fragmented {
    opacity: 0.5;
  }

  .enabled:hover,
  .enabled.fragmented:hover {
    opacity: 1;
  }
}

.reveal:not(.rtl) .controls {
  // Back arrow style: "faded":
  // Deemphasize left arrow
  &[data-controls-back-arrows="faded"] .navigate-left.enabled {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  // Back arrow style: "hidden":
  // Never show left arrow
  &[data-controls-back-arrows="hidden"] .navigate-left.enabled {
    opacity: 0;
    visibility: hidden;
  }
}

.reveal.rtl .controls {
  // Back arrow style: "faded":
  // Deemphasize right arrow in RTL mode
  &[data-controls-back-arrows="faded"] .navigate-right.enabled  {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  // Back arrow style: "hidden":
  // Never show right arrow in RTL mode
  &[data-controls-back-arrows="hidden"] .navigate-right.enabled {
    opacity: 0;
    visibility: hidden;
  }
}

.reveal[data-navigation-mode="linear"].has-horizontal-slides .navigate-up,
.reveal[data-navigation-mode="linear"].has-horizontal-slides .navigate-down {
  display: none;
}

// Adjust the layout when there are no vertical slides
.reveal[data-navigation-mode="linear"].has-horizontal-slides .navigate-left,
.reveal:not(.has-vertical-slides) .controls .navigate-left {
  bottom: $controlArrowSpacing;
  // EDIT: MOVE LEFT TO LEFT SIDE -- right: 0.5em + $controlArrowSpacing + $controlArrowSize;
  left: $controlArrowSpacing/2;
}

.reveal[data-navigation-mode="linear"].has-horizontal-slides .navigate-right,
.reveal:not(.has-vertical-slides) .controls .navigate-right {
  bottom: $controlArrowSpacing;
  right: 0.5em;
}

// Adjust the layout when there are no horizontal slides
.reveal:not(.has-horizontal-slides) .controls .navigate-up {
  right: $controlArrowSpacing;
  bottom: $controlArrowSpacing + $controlArrowSize;
}
.reveal:not(.has-horizontal-slides) .controls .navigate-down {
  right: $controlArrowSpacing;
  bottom: 0.5em;
}

// Invert arrows based on background color
.reveal.has-dark-background .controls {
  color: #fff;
}
.reveal.has-light-background .controls {
  color: #000;
}

// Disable active states on touch devices
.reveal.no-hover .controls .controls-arrow:hover,
.reveal.no-hover .controls .controls-arrow:active {
  @include controlsArrowTransform( $controlsArrowAngle );
}

// Edge aligned controls layout
@media screen and (min-width: 500px) {

  $spacing: 0.8em;

  .reveal .controls[data-controls-layout="edges"] {
    & {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .navigate-left,
    .navigate-right,
    .navigate-up,
    .navigate-down {
      bottom: auto;
      right: auto;
    }

    .navigate-left {
      top: 50%;
      left: $spacing;
      margin-top: -$controlArrowSize/2;
    }

    .navigate-right {
      top: 50%;
      right: $spacing;
      margin-top: -$controlArrowSize/2;
    }

    .navigate-up {
      top: $spacing;
      left: 50%;
      margin-left: -$controlArrowSize/2;
    }

    .navigate-down {
      bottom: $spacing - $controlArrowSpacing + 0.3em;
      left: 50%;
      margin-left: -$controlArrowSize/2;
    }
  }

}
